<template>
    <div class="riskItemView-checklist">
      <el-container>
        <el-main>
          <grid-head-layout ref="gridHeadLayout" :search-span="3" :search-columns="searchColumns" @grid-head-search="gridHeadSearch" @grid-head-empty="gridHeadEmpty"></grid-head-layout>
          <grid-layout
              ref="gridLayOut"
              :table-options="tableOption"
              :table-data="tableData"
              :table-loading="tableLoading"
              @gird-handle-select-click="selectionChange"
              :data-total="page.total"
              :page="page"
              @page-current-change="onLoad"
              @page-size-change="onLoad"
            >
            <template #hdLevel="{row}">
              <el-tag v-if="row.hdLevel" :type="hdLevelIcon[row.hdLevel]"
                effect="dark">{{row.$hdLevel}}
              </el-tag>
            </template>
            <template #hdStatus="{row}">
              <el-tag effect="dark" class="hdStatus" v-if="row.hdStatus && !row.outOfTime" :style="{backgroundColor:hdStatusIcon[row.hdStatus],borderColor:hdStatusIcon[row.hdStatus]}">{{row.$hdStatus}}</el-tag>
              <el-tag effect="dark" class="hdStatus" v-if="row.outOfTime" :style="{backgroundColor:'#bd3124',borderColor:'#bd3124'}">超期未整改</el-tag>
            </template>
            <template #customBtn="{row}">
              <el-button type="text" size="small"  @click="changeRow(row)">选择</el-button>
            </template>
          </grid-layout>
        </el-main>
      </el-container>
      <div v-if="multipleType" style="display: flex;justify-content: flex-end;border-top: 1px solid #CCCCCC;padding-top: 30px">
        <el-button size="mini" @click="closeDialog">取消</el-button>
        <el-button size="mini" type="primary" @click="confirm">确认</el-button>
      </div>
    </div>
  </template>
  <script>
    import HeadLayout from "@/views/components/layout/head-layout";
    import GridLayout from "@/views/components/layout/grid-layout";
    import * as  API from "@/api/riskitemView/checklist";
    // import checklist from "@/views/hiddenTrouble/riskItemView/checklist/checklist";
    import riskView from "@/views/hiddenTrouble/riskItemView/riskView";
    import {levelStatistics, statusStatistics} from "@/api/riskitemView/checklist";
    import {fieldInfo} from '@/api/base/length'
    import {mapGetters} from "vuex";

  export default {
    mixins:[riskView],
    components: {
      GridLayout,
      HeadLayout
    },

    data() {
      return {
        searchColumns: [
                {
                    label: '隐患编号',
                    prop: "hdCode",
                    span: 4,
                    placeholder: '请输入隐患编号'
                }, {
                    label: '隐患描述',
                    prop: "hdDesc",
                    span: 4,
                    placeholder: '请输入隐患描述'
                },
                {
                    label: '隐患级别',
                    prop: "hdLevel",
                    span: 4,
                    type: 'select',
                    placeholder: '请选择隐患级别',
                    dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_level`,
                    props: {
                        label: 'dictValue',
                        value: 'dictKey',
                    },
                },
                {
                    label: '地点',
                    prop: "location",
                    span: 4,
                    placeholder: '请输入地点'
                },
                {
                    label: '报告人',
                    prop: "reportUserId",
                    span: 4,
                    placeholder: '请输入报告人',
                },
                {
                    label: '开始时间',
                    prop: "startTime",
                    type: 'date-format',
                    span: 4,
                    placeholder: '请选择开始时间'
                },
                {
                    label: '结束时间',
                    prop: "endTime",
                    type: 'date-format',
                    span: 4,
                    placeholder: '请选择结束时间'
                },
        ],
        tableData: [],
        tableLoading: false,
        page: {
            pageSize: 20,
            currentPage: 1,
            total: 0,
        },
      }
    },

    props: {
      multipleType: {
        type: Boolean,
        default: false
      }
    },

    computed: {
        ...mapGetters(["userInfo"]),
        tableOption () {
          return {
            rowKey: "id",
            linklabel: "hdCode",
            selection: true,
            column: [{
                label: "隐患编号",
                prop: "hdCode",
                align: "left",
                overHidden: true,
                width: 180
              },
              {
                label: "隐患描述",
                prop: "hdDesc",
                align: "left",
                overHidden: true,
                width: 200
              },
              {
                label: "隐患级别",
                prop: "hdLevel",
                type: 'select',
                dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_level`,
                props: {
                  label: 'dictValue',
                  value: 'dictKey',
                },
                align: fieldInfo.Words5.align,
                minWidth: fieldInfo.Words5.length,
                overHidden: true,
                slot: true,
              },
              {
                label: "隐患地点",
                prop: "location",
                align: fieldInfo.fullAddress.align,
                minWidth: fieldInfo.fullAddress.length,
                overHidden: true,
              },
              {
                label: "报告人",
                prop: "reportUserName",
                align: fieldInfo.name_person.align,
                minWidth: fieldInfo.name_person.length,
                overHidden: true,
              },
              {
                label: "创建时间",
                prop: "createTime",
                align: fieldInfo.datetime.align,
                minWidth: fieldInfo.datetime.length,
                overHidden: true,
              },
            ],
          }
        }
    },

    created() {
        this.onLoad(this.page);
    },

    methods: {
      // 搜索
      gridHeadSearch(searchForm) {
        this.page.currentPage = 1;
        this.onLoad(this.page, searchForm);
      },
      // 清空
      gridHeadEmpty(searchForm) {
        this.page.currentPage = 1;
        this.$refs.gridHeadLayout.searchForm = {};
        this.onLoad(this.page, searchForm);
      },
      // 选择列表数据
      selectionChange(list) {
        this.selectionList = list;
      },
      // 获取列表数据
      onLoad(page, params) {
        this.page = page;
        this.tableLoading = true;
        API.getList({current:page.currentPage, size:page.pageSize, ...params,organizationId:this.userInfo.dept_id,
        }).then((res) => {
          if (res.data.code == 200) {
            this.tableLoading = false;
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = res.data.data.total;
          }
        })
      },
      changeRow (row) {
        this.$emit('changeData',[row])
      },
      //请选择人员
      confirm() {
        this.$emit('changeData', this.selectionList);
        this.closeDialog();
      },
      //清楚人员
      closeDialog() {
        this.$refs.gridLayOut.grid.toggleSelection()
        this.$emit('changeData');
      },
    }
  }

  </script>
  <style lang="scss" >
  // .riskItemView-checklist{
  //   width: 100%
  // }
//     .avue-crud .el-table {
//       height: calc(100vh - 340px) !important;
//       max-height: calc(100vh - 340px) !important;
//     }

//   .charts-box{
//     padding-top: 15px;
//     padding-bottom: 15px;
//     display: flex;
//     justify-content: space-between;
//     .no-border-right{
//       border-right:0;
//     }

//   }
//     .charts-box{
//       height: 260px;
//     }
//     .charts-icon{
//       width: calc(100% / 3 - 16px);
//       height: 260px;
//     }
//     .pie-box{
//       margin-top: 15px;
//     }
//     .pie{
//       width: 100%;
//     }
//   }
  </style>
